@import '../variables.less';

.msg-container{
  text-align: left;
  .para{
    color: #4A71A1;
    font-weight: bold;
  }
}

.cursor-pointer{
  cursor: pointer;
}