@import '../variables.less';

.custom-scroll::-webkit-outer-spin-button,
.custom-scroll::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* width */
  .custom-scroll::-webkit-scrollbar {
    width: 7px;
  }

  // /* Track */
  .custom-scroll::-webkit-scrollbar-track {
    background: rgb(168, 164, 164);
  }

  // /* Handle */
  .custom-scroll::-webkit-scrollbar-thumb {
    background: #4A71A1;
    border-radius: 55px;
  }

  // /* Handle on hover */
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #4A71A1;
    border-radius: 55px;
  }

.package-item{
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .checkout-container{
    margin-top: 25px;
    text-align: center;
    padding: 0 10px;
    width: 100%;
    h3{
      font-size: 20px;
      font-weight: 600;
      color: @theme-blue;
    }
  }
  footer{
    display: flex;
    justify-content: center;
    button{
      margin-top: 10px;
      border-radius: 25px;
      background-color: @theme-green;
      color: white;
      &:hover{
        background-color: @theme-green;
        color: white;
      }
    }
    #contact-us{
      background-color: @theme-blue;
    }
  }
}

.package-card{
  width: 270px;
  border: 1px solid @theme-blue;
  border-top: 5px solid @theme-blue;
  border-radius: 10px;
  height: 625px;
  overflow: hidden;
  animation: animate 1s ease-in-out;
  &:hover{
    box-shadow: 5px 10px 5px rgba(0,0, 0, .5);
  }
  header{
    p:nth-child(1){
      font-family: cursive;
      font-size: 25px;
      margin-bottom: 0;
    }
    p:nth-child(2){
      font-size: 20px;
      font-family: cursive;
      min-height: 10px;
    }
  }
  #pricing{
    span[id="main"]{
      font-size: 25px;
      color: @theme-green;
      font-weight: 600;
    }
    span[id="sec"]{
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.package-card .ant-card-body{
  padding: 24px 0;
}

@keyframes animate{
  0%{
    transform: rotateY(110deg);
  }
  100%{
    transform: rotateY(0deg);
  }
}

.dropup{
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.dropup>span{
  border-radius: 50%;
  padding: 20px;
  transform: rotate(135deg);
  top: 0;
  position: relative;
  z-index: 1;
}
.dropup>span::after{
  content: '';
  border: 2px solid black;
  border-left: none;
  border-bottom: none;
  position: absolute;
  color: black;
  width: 10px;
  height: 10px;
  background: transparent;
  animation: animatearrow 1s ease-in-out infinite;
}
.dropup:hover{
  cursor: pointer;
  &>span{
    top: 10px;
  }
  &>span::after{
    content: '';
  }
  & > .extra-details{
    display: block;
    animation: extracontent .2s linear;
  }
}

@keyframes extracontent {
  0%{
    height: 0px;
  }
  100%{
    height: 100%;
  }
}

.extra-details{
  display: none;
  background: white;
  width: 100%;
  color: black;
  padding: 15px 10px;
  z-index: 2;
  button{
    border-radius: 25px;
    color: white;
    background-color: @theme-green;
    &:hover{
      color: white;
      background-color: @theme-green;
    }
  }
  p{
    font-size: 12px;
    // color: rgb(75, 72, 72);
  }
  ul{
    list-style: none;
    li{
      text-align: center;
    }
  }
  .extra-features{
    p{
      line-height: 0.9;
      font-size: 14px;
    }
    p::after{
      content: '';
      border-bottom: 2px solid black;
    }
  }
}

@keyframes animatearrow {
  0%{
    transform: translate(-50%, -0px) rotate(-45deg);
  }
  100%{
    transform: translate(-50%, -5px) rotate(-45deg);
  }
}


.payment-form-container{
  header{
    display: flex;
    .img-container{
      margin-bottom: 20px;
    }
    h2{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: @grey;
    }
  }
  .form-item{
    border: 1px solid @grey;
    margin-bottom: 0;
  }
  .ant-picker{
    width: 100%;
  }
}

.success-page{
  text-align: center;
  h2{
    color: @success-typo;
  }
  .anticon{
    font-size: 150px;
    margin-bottom: 25px;
    color: @success-typo;
  }
  h4{
    color: @grey;
    border: 0.1px solid @success-typo;
    padding: 10px;
    box-shadow: 1px 1px 10px @success-typo inset;
  }
}

.tnc-container{
  h1{
    text-align: center;
  }
  #btn-container{
    display: flex;
    justify-content: center;
  }
}

.payment-header-logo{
  margin: 20px 0;
  width: 400px;
}

@media screen and (max-width: 556px){
  .payment-header-logo{
    width: 250px;
  } 
}