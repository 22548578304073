@import '../variables.less';

.active-card(@width){
  width: @width;
  height: 150px;
  border-radius: 10px;
  padding: 0 5px;
}

.item(){
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  height: 100%;
}

.dash-btn(){
  color: @white;
  background-color: @theme-darkblue;
}

.company-container{
  transform: translateY(-10px);
  h3{
    background-color: @theme-blue;
    color: @white;
    padding: 7px 7px;
  }
  .company-details{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid gray;
    p{
      text-align: left;
      margin-bottom: 0;
      word-break: break-all;
      strong{
        font-size: 16px;
      }
    }
    p[id="title"]{
      color: @theme-blue;
      font-size: 20px;
      font-weight: bold;
    }
    button{
      margin-top: 5px;
      .dash-btn();
    }
  }
}
.general-announcement-container{
  padding: 5px 10px;
  box-shadow: 1px 1px 5px grey;
  .announcement-list{
    margin-top: 40px;
    padding: 0 10px;
    td:nth-child(2){
      text-align: left;
    }
    h4>strong{
      color: @theme-blue;
      font-size: 18px;
    }
  }
  h3{
    padding: 5px 10px;
    background-color: @theme-blue;
    margin-bottom: 0;
    color: @white;
    text-align: left;
  }
  textarea{
    border-radius: 0 0 15px 15px;
  }
  .send-btn {
    position: relative;
    button{
      position: absolute;
      right: 20px;
      top: -16px;
      font-size: 20px;
      border: none;
      background: @theme-blue;
      border-radius: 50%;
      padding: 0 5px;
      color: white;
      &:hover {
        cursor: pointer;
      }
      &:focus{
        outline: none;
      }
    }
  }
}
.announcement-container{
  background-color: white;
  .heading-block{
    h3{
      font-size: 20px;
    }
  }
  .msgs-container{
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
    .msg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      padding: 5px;
      border-radius: 10px;
      background-color: whitesmoke;
      margin-bottom: 5px;
      position: relative;
      .title, strong{
        color: @theme-blue;
        font-weight: bold;
        font-size: 18px;
      }
      .para{
        font-size: 18px;
      }
      &:focus{
        outline: none;
      }
    }
    button{
      .dash-btn();
      border: none;
      margin-left: 5px;
    }
  }
  .news{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-radius: 10px;
    background-color: whitesmoke;
    margin-bottom: 5px;
  }
  .heading-block{
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h3{
      padding: 5px 10px;
      margin-bottom: 0;
      font-weight: bold;
      text-align: left;
    }
  }
}

.dashboard-container{
  height: 100%;
  width: 100%;
  padding: 20px 0 20px 20px;
  .tables-container{
    th{
      text-align: left;
      color: @theme-blue;
    }
    td{
      text-align: left;
      button{
        .dash-btn();
      }
    }
    a{
      position: absolute;
      right: 15px;
      top: 0;
      z-index: 1;
    }
    .heading-block{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      h3{
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 0;
        margin-left: 10px;
        text-align: left;
      }
      small{
        float: left;
      }
    }
    .ghost-subheading{
      height: 15px;
    }
  }
  .float-chat{
    padding: 10px;
    #chat{
      border-radius: 50%;
      background-color: #5F9527;
      padding: 5px;
      border: none;
      color: white;
      float: right;
      span{
        font-size: 30px;
      }
      &:hover{
        cursor: pointer;
      }
      &:focus{
        outline: none;
      }
    }
  }
  .active-comp-container{
    display: flex;
    overflow-x: auto;
    .active-comp-card{
      .active-card(100%);
      .active-comp-item{
        .item();
      }
    }
    .unused-comp-card{
      .active-card(300px);
      .comp-item{
        .item();
      }
    }
  }
  .submit-btn{
    margin: 10px 0;
  }
}

.lookahead-dash-container{
  transform: translateY(-12px);
  text-align: left;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  .title, strong{
    color: @theme-blue;
    font-size: 16px;
  }
  .para{
    font-size: 15px;
  }
  &:focus{
    outline: none;
  }
  button{
    .dash-btn();
  }
}

.bookmark_dash{
  margin-bottom: -10px;
  text-align: left;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 0;
  #content{
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  button{
    .dash-btn();
  }
}