@import '../variables.less';

.ant-layout-has-sider{
    .ant-layout-sider{
        background-color: @white;
        text-align: center;
        margin: 0 15px 0 0;
        padding: 5px;
        width: 200px;
    }
}


@media screen and (max-width: 556px){
    #current-month{
        display: none;
    }
    .ant-layout-has-sider{
        .ant-layout-sider{
            display: none;
        }
        main[class="ant-layout-content"]{
            margin-left: 15px;
        }
    }
}