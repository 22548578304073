.change-passowrd-container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  .login-passord-form{
    width: 450px;
  }
  .ant-image {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    img{
      width: 450px;
    }
  }

  @media screen and (max-width: 567px){
    .ant-image img{
      width: 300px;
    } 
    .login-passord-form{
      width: 300px;
    }
  }
}