@import './variables.less';

.ant-menu-horizontal {
  background-color: var(--brandBlueLight);
  .ant-menu-item{
    a{
      color: white;
      &:hover{
        color: rgba(0,0,0,.65);
      }
    }
  }
  .ant-menu-item-active {
    border-bottom: 2px solid var(--brandGreenLight) !important;
    background-color: var(--brandWhite) !important;
  }
  .ant-menu-item-selected {
    border-bottom: 2px solid var(--brandGreenLight) !important;
    background-color: #e3e3e3 !important;
    a{
      color: rgba(0,0,0,.65);
    }
  }
}

.ant-menu-submenu{
  .ant-menu-sub{
    a{
      color: white;
    }
    .ant-menu-item-selected{
      background: #e3e3e3 !important;
      a{
        color: rgba(0,0,0,.65);
      }
    }
  }
}

.ant-layout-content{
  margin-right: 15px;
}
.ant-layout-header{
  padding: 0 25px;
}
.heading-title, h2.heading-title{
  font-weight: bold;
  color: #4A71A1;
}

.sidebar-search{
  label, #label{
    display: flex;
    align-items: center;
  }
  .checkbox-label{
    width: 130px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ant-radio-wrapper{
  white-space: pre-wrap;
}

.association-form, .small-business-form, .prime-form{
  .ant-input[disabled], 
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item,
  .ant-radio-disabled + span,
  .ant-checkbox-disabled + span{
    color: rgb(90, 88, 88);
  }
}

@media screen and (max-width: 556px){
  .calendar_container .ant-badge-status-text{
    display: none;
  }
  .calendar-action-container{
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    .ant-space-item{
      border: 0.1px solid black;
      margin: 0 2px;
      padding: 5px;
    }
    .ant-space-item:first-child::before{
      content: "Edit ";
    }
    .ant-space-item:nth-child(2)::before{
      content: "Delete ";
    }
  }
  .heading-title, h2.heading-title{
    font-size: 20px;
  }
  .ant-modal-body{
    padding: 12px;
    .ant-card-body{
      padding: 5px;
    }
  }
}

.ant-menu-submenu{
  .-title-content{
    color: white;
  }
}