.Tooltips-Content {
  * {
    color: white;
  }
}

.ant-tooltip-inner {
  background-color: hsla(0, 0%, 0%, 0.8);
  border-radius: 5px;
  text-align: center;
}
