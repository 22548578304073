.table-striped-rows {
  thead th,
  td {
    text-align: center;
  }

  tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
}

th{
  background-color: @theme-blue !important;
  color: white !important;
}
.no-header{
  th{
    background-color: transparent !important;
    color: white !important;
    padding: 0 !important;
  }
}
.table-hoverable{
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
      background: unset; //Change the existing color to `unset`
      cursor: pointer;
      background-color: lightgray;
  }
  tr:hover, td:hover{
    cursor: pointer;
    background-color: lightgray !important;
  }
}
