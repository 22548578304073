@import '../variables.less';

.ant-menu-submenu-popup{
  .ant-menu{
    background-color: @theme-blue;
  }
}

.section-headers{
  font-size: 25px;
  font-weight: bold;
  color: @theme-blue;
  margin-bottom: 0px;
  text-align: left;
}

.divider{
  border-bottom: 1px solid @black;
  margin-bottom: 20px;
}

.ant-layout-header{
  height: 72px;
  width: 100%;
  color: @white;
  background-color: @theme-darkblue;
  .header-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    svg[data-icon="setting"]{
      color: @white;
    }
    .anticon-menu{
      display: none;
    }
  }
}

.ant-layout-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 0 0 0;
  margin-top: 0;
  color: @white;
  background-color: @theme-blue;
  z-index: 100;
}


@media screen and (max-width: 556px){
  .section-headers{
    font-size: 15px;
  }
  .header-section{
    h3{
      display: none;
    }
  }
  .ant-layout-header{
    .header-section{
      .anticon-menu{
        display: block;
        margin-top: 10px;
      }
    }
  }
  .ant-drawer-header{
    padding: 0;
    .sidebar-header{
      padding: 16px 10px;
      background-color: @theme-darkblue;
    }
  }
}