@import '../variables.less';

.profile-container{
  padding: 20px;
  img{
    border-radius: 50%;
    border: 3px solid @darkblue;
  }
  .anticon{
    position: relative;
    top: -15px;
    right: 20px;
    background: @darkblue;
    border-radius: 50%;
    color: @white;
    padding: 2px;
  }
  .anticon:hover{
    cursor: pointer;
  }
}