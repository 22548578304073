.video-container{
    padding: 10px;
    .gutter-row{
        margin-bottom: 15px;
    }
    .video-item{
        border-radius: 10px;
        overflow: hidden;
        border: 0.1px solid lightgray;
        height: 270px;
        position: relative;
        video{
            width: 100%;
        }
        .details{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: left;
            padding: 10px 15px;
            background-color: white;
            img{
                margin-right: 10px;
            }
        }
    }
}