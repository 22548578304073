@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;0,700;1,100;1,500;1,700&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  color: #000;

  --brandBlueLight: #1d4e89;
  --brandBlueDark: #131e37;
  --brandGreenLight: #7bbf22;
  --brandWhite: #e3e3e3;
}
strong {
  font-weight: bold;
  text-transform: capitalize;
}
@import './styles/components/Table.less';
@import './styles/components/Payments.less';
@import './styles/components/dashboard.less';
@import './styles/components/profile.less';
@import './styles/components/addUsers.less';
@import './styles/components/header.less';
@import './styles/components/announcement.less';
@import './styles/components/changePassword.less';
@import './styles/components/auth.less';
@import './styles/components/pageLayout.less';
@import './styles/ui/Tooltips.less';
@import './styles/ui/MenuBar.less';
@import './styles/components/video.less';

@import './styles//override.less';

@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);